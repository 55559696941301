import React from 'react';

const CricketPoster = () => {
  return (
    <div className="relative h-screen flex items-center  justify-center ">
      <img
        src="/ballimg.jpg"
        alt="Bonus Image"
        className="w-full h-full object-contain"
      />
  
      <div className="absolute flex flex-col items-center justify-center bottom-40 md:bottom-10 z-10">
        <a href="https://wa.link/interaktadsgoogle">
          <button className="bg-gradient-to-r from-red-800 via-orange-500 to-red-500 font-semibold uppercase rounded-md px-6 py-2 md:px-10 md:py-3 text-white animate-bounce mt-4 text-sm md:text-lg">
            Claim Now
          </button>
        </a>
      </div>
    </div>
  );
};

export default CricketPoster;